import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import emailjs from '@emailjs/browser';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { SectionHeading } from 'components/misc/Headings.js';
import { Input } from 'components/misc/InputWithValidation';
import Fb_icon from '../../assets/social/fb_icon.png';
import Ig_icon from '../../assets/social/ig_icon.png';

const Container = tw(
  ContainerBase
)`flex relative bg-primary-purple text-primary-blue py-8 lg:py-16 max-md:p-8 items-center justify-center`;
const GridContainer = tw.div`flex flex-row max-md:flex-col max-md:gap-8 gap-48 max-md:px-4`; // Widening the columns
const FormContainer = tw.div`grid grid-cols-1 md:grid-cols-2 gap-2 w-full`; // Adjusting the form width
export const PrimaryButtonBase = tw.button`px-8 py-3 font-bold text-xl rounded-lg bg-secondary-teal text-primary-blue hocus:bg-primary-purple focus:shadow-outline focus:outline-none transition duration-300`;
const ContactContainer = tw.div`lg:min-w-[600px]`;

const SubmitButton = tw(PrimaryButtonBase)`w-full md:w-auto mt-4`; // Button styling
const Heading = tw(
  SectionHeading
)`font-black text-left text-2xl sm:text-3xl lg:text-4xl leading-tight text-primary-blue mb-4`;

const SocialMediaContainer = tw.div`flex flex-col relative justify-end items-start max-md:items-center gap-32 max-md:gap-4 space-y-6`; // Centering and adding spacing
const SocialMediaSection = tw.div`flex flex-col items-start space-y-4`; // Center social media icons and text
const SocialMediaLinks = tw.div`flex items-center space-x-4  
`;
const SocialImg = tw.img`w-12 h-12`;
const SubLinks = tw.div`flex space-x-4 text-sm text-gray mt-auto`; // Sub-links styling (Home, About, etc.), aligned to the bottom
const Copyright = tw.span`text-sm text-gray mt-4`;
export default ({
  heading = 'Contact us directly',
  submitButtonText = 'Send',
}) => {
  const methods = useForm();
  const [showSuccess, setShowSuccess] = useState(false);

  const sendEmail = methods.handleSubmit((data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID,
        data,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setShowSuccess(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  });

  return (
    <Container>
      <GridContainer>
        {/* Form Section */}
        <ContactContainer>
          <Heading>{heading}</Heading>
          {showSuccess ? (
            <p className='text-lg text-primary-blue'>
              Thank you! Your message has been sent.
            </p>
          ) : (
            <FormProvider {...methods} noValidate autoComplete='off'>
              <form onSubmit={sendEmail}>
                <FormContainer>
                  <Input
                    name='from_name'
                    type='text'
                    id='from_name'
                    placeholder='Your full name'
                    validation={{
                      required: {
                        value: true,
                        message: 'Full name is required',
                      },
                    }}
                  />
                  <Input
                    name='from_petName'
                    type='text'
                    id='from_petName'
                    placeholder="Pet's name"
                    validation={{
                      required: {
                        value: true,
                        message: "Pet's name is required",
                      },
                    }}
                  />
                  <Input
                    name='from_email'
                    type='email'
                    id='from_email'
                    placeholder='Email address'
                    validation={{
                      required: {
                        value: true,
                        message: 'Email is required',
                      },
                    }}
                  />
                  <Input
                    name='subject'
                    type='text'
                    id='subject'
                    placeholder='Location'
                  />
                </FormContainer>
                <Input
                  name='message'
                  isTextArea={true}
                  id='message'
                  placeholder='Message'
                  validation={{
                    required: {
                      value: true,
                      message: 'Message is required',
                    },
                  }}
                  className='md:col-span-2' // Message box spans two columns
                />
                <div className='flex justify-start md:justify-end mt-4'>
                  <SubmitButton type='submit'>{submitButtonText}</SubmitButton>
                </div>
              </form>
            </FormProvider>
          )}
        </ContactContainer>

        {/* Social Media and Sub Links Section */}
        <SocialMediaContainer>
          {/* Social Media Section */}
          <SocialMediaSection>
            <div className='font-semibold text-lg'>Connect with us!</div>
            <SocialMediaLinks>
              <a
                href='https://www.instagram.com/retrieverrecords.vet/'
                aria-label='Facebook'
              >
                <SocialImg src={Fb_icon} alt='Facebook' />
              </a>
              <a
                href='https://www.facebook.com/profile.php?id=61554254173681'
                aria-label='Instagram'
              >
                <SocialImg src={Ig_icon} alt='Instagram' />
              </a>
            </SocialMediaLinks>
          </SocialMediaSection>
          <SocialMediaSection>
            <SubLinks>
              <a href='/#' key='home'>
                Home
              </a>
              <a href='/about'>About</a>
              <a href='/forvets'>For Vets</a>
              <a href='/signup'>Sign Up</a>
              <a href='/login'>Log In</a>
            </SubLinks>
            <Copyright>Copyright © 2024</Copyright>
          </SocialMediaSection>
        </SocialMediaContainer>
      </GridContainer>
    </Container>
  );
};
