import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components'; //eslint-disable-line
import { css } from 'styled-components/macro'; //eslint-disable-line
import { SectionHeading } from 'components/misc/Headings.js';
import { NavyContainer, Container } from 'components/misc/Layouts.js';
import { ReactComponent as PlusIcon } from 'feather-icons/dist/icons/plus.svg';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

const HeadingRow = tw.div``;
const Column = tw.div`flex-col items-center p-16 max-w-full`;
const Heading = tw(SectionHeading)`text-left xl:text-5xl`;
const Question = tw(SectionHeading)`text-left lg:text-lg text-center`;
const Answer = tw.p`mt-4 leading-loose w-full font-body`;

// Styled components for layout adjustment
const StyledAccordion = styled(Accordion)`
  background-color: inherit !important;
  color: white !important;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  display: flex;
  align-items: center;

  .icon {
    ${tw`mr-4 flex-shrink-0`}
  }

  .text {
    ${tw`flex-grow`}
  }
`;

export default () => {
  return (
    <>
      <NavyContainer>
        <Column>
          <HeadingRow>
            <Heading>Frequently asked questions</Heading>
          </HeadingRow>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>What is Retriever Veterinary Records?</Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                Retriever is the only universal, easily accessible medical
                records database for your pet&apos;s medical records.
              </Answer>
              <Answer>
                We compile and organize your pet&apos;s medical records so that
                when you need them, they&apos;re there. They&apos;re also
                searchable by service provided at that visit - labwork,
                procedures, prescriptions, etc, so that information can be
                easily accessed by you or your veterinarian when you need it
                most.
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>Do I need Retriever?</Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                If you&apos;ve been to more than one veterinarian for your pet
                (that includes your general practitioner and an emergency
                hospital, urgent care, specialist, etc), then YES!
              </Answer>
              <Answer>
                Right now, there&apos;s no way to share medical records between
                hospitals without calling and asking in advance (or most often,
                once the pet is already there and being seen). There&apos;s no
                guarantee that records will even arrive, and if they do,
                they&apos;re often late or incomplete. That leads to unwanted
                stress, repeated tests and missed diagnoses - your pets deserve
                better.
              </Answer>
              <Answer>
                Even if you&apos;ve only been to your primary veterinarian,
                Retriever is still for you! If your vet is ever closed (like on
                holidays and weekends), or you plan on traveling with your pet,
                Retriever is an extra safety net that if you end up at an
                unfamiliar vet, they have all the information they need.
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>How does Retriever work?</Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                Retriever is pretty simple - you sign up, and put in some basic
                personal information (name, phone number, email address,
                physical address), and then register your pet (or as many as you
                want!).
              </Answer>
              <Answer>
                When you register your pet, you&apos;ll list the veterinarians
                they&apos;ve been to, where there could be medical records.
                Automatically, a request for records goes to your vet. Any time
                you go to the vet, you click the “request records” button on
                your pet&apos;s portal, and an automatic request goes to the
                clinic of your choosing.
              </Answer>
              <Answer>
                Once we get the records from your veterinarian, we make sure
                that they&apos;re complete (no missing tests, complete record),
                and then we upload them to your pet&apos;s portal!
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>What do I need to do?</Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                Aside from signing up, the only thing you need to do is let us
                know when you go to the vet! That&apos;s achieved easily by
                going to your pet&apos;s portal and clicking “Request Records”
                in the top right hand corner.
              </Answer>
              <Answer>
                Think of it this way - when you&apos;re checking out at the vet,
                or submitting a claim to insurance, just head to Retriever and
                their visit will be complete!
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>Can I do things myself?</Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                If that&apos;s what you prefer, totally fine! The goal of
                Retriever is to make life easier for you, but if you want to use
                it as a database that you organize yourself, that&apos;s also
                fine! Just let us know so that we don&apos;t double up on
                requests from your vet!
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>
                What safety measures do you have in place to protect my and my
                pet&Question;s data?
              </Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                A few! Our data is encrypted and password protected so that your
                information can&apos;t be obtained by anyone else unless they
                have your password and login information. All of our information
                is stored in the cloud and our software team has taken extra
                steps to make sure your data doesn&apos;t end up in anyone
                else&apos;s hands.
              </Answer>
              <Answer>
                The records we get from veterinarians have your pet&apos;s data
                (and often, your name and address) but do not include any
                financial data (even if your vet has your credit card on file).
                It never enters our system.
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion slotProps={{ heading: { component: 'h3' } }}>
            <StyledAccordionSummary
              expandIcon={null} // Remove default expandIcon
              aria-controls='q1-content'
              id='q1-header'
            >
              <PlusIcon className='icon' />
              <Question>What if records aren&apos;t showing up? </Question>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Answer>
                It probably means we haven&apos;t gotten them yet!
              </Answer>
              <Answer>
                After we have received your records request, you should be able
                to see the status of the request in the grey tab on your
                pet&apos;s patient portal. If we haven&apos;t received records
                in 48 hours, we request again. If there is trouble after that,
                we&apos;ll let you know! And if you have any questions, you can
                always email help@retriever.vet
              </Answer>
            </AccordionDetails>
          </StyledAccordion>
        </Column>
      </NavyContainer>
    </>
  );
};
