import React from 'react';
import GlobalStyles from 'styles/GlobalStyles';

import Layout from 'components/layout/Layout';
import Home from 'pages/Home.js';
import About from 'pages/About';
import AdminHome from 'pages/AdminHome';
import Signup from 'pages/Signup';
import Login from 'pages/Login';
import Contact from 'pages/ContactUs';
import MyPets from 'pages/MyPets';
import AddPet from 'pages/AddPet';
import PetDashboard from 'pages/PetRecordsDashboard';
import AddNewPetFile from 'pages/AddNewPetFile';
import EditPetFile from 'pages/EditPetFile';
import RequestRecords from 'pages/RequestRecords';
import ForVets from 'pages/ForVets';
import EditPet from 'pages/EditPet';
import AdminUserDashboard from 'pages/AdminUserDashboard';
import { ProtectedRoute } from './helpers/ProtectedRoute';
import { AuthProvider } from './helpers/useAuth';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ClinicDashboard from 'pages/ClinicDashboard';
import AddClinic from 'pages/AddClinic';
import EditClinic from 'pages/EditClinic';
import AdminRecordRequestsDashboard from 'pages/AdminRecordRequestsDashboard';
import FAQ from 'pages/FAQ';

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <AuthProvider>
          <Layout>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/faq' element={<FAQ />} />
              <Route path='/login' element={<Login />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/forvets' element={<ForVets />} />
              <Route
                path='/mypets'
                element={
                  <ProtectedRoute>
                    <MyPets />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/addpet'
                element={
                  <ProtectedRoute>
                    <AddPet />
                  </ProtectedRoute>
                }
              />
              <Route
                path='medicalRecords/:petId'
                element={
                  <ProtectedRoute>
                    <PetDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path='editPet/:petId'
                element={
                  <ProtectedRoute>
                    <EditPet />
                  </ProtectedRoute>
                }
              />
              <Route
                path='medicalRecords/:petId/addFile'
                element={
                  <ProtectedRoute>
                    <AddNewPetFile />
                  </ProtectedRoute>
                }
              />
              <Route
                path='medicalRecords/:petId/editFile/:fileId'
                element={
                  <ProtectedRoute>
                    <EditPetFile />
                  </ProtectedRoute>
                }
              />
              <Route
                path='medicalRecords/:petId/requestRecords'
                element={
                  <ProtectedRoute>
                    <RequestRecords />
                  </ProtectedRoute>
                }
              />
              <Route
                path='admin'
                element={
                  <ProtectedRoute>
                    <AdminHome />
                  </ProtectedRoute>
                }
              />
              <Route
                path='admin/users'
                element={
                  <ProtectedRoute>
                    <AdminUserDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path='admin/clinics'
                element={
                  <ProtectedRoute>
                    <ClinicDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path='admin/clinics/new'
                element={
                  <ProtectedRoute>
                    <AddClinic />
                  </ProtectedRoute>
                }
              />
              <Route
                path='admin/clinics/:clinicId'
                element={
                  <ProtectedRoute>
                    <EditClinic />
                  </ProtectedRoute>
                }
              />
              <Route
                path='admin/recordRequests'
                element={
                  <ProtectedRoute>
                    <AdminRecordRequestsDashboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Layout>
        </AuthProvider>
      </Router>
    </>
  );
}
